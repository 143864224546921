import { useContext, useEffect, useState } from "react";
import { GlobalDataContext } from "../../context/context";
import axios from "axios";
import { FaStar } from "react-icons/fa";


export default function CustomReviews() {

    const { rpdata } = useContext(GlobalDataContext);

    const [reviewsDB, setReviewsDB] = useState([]);
    const idCompany = rpdata?.docId;

    useEffect(() => {
        const getReviews = async () => {
            const url = `https://apireviews.herokuapp.com/api/reviews/${idCompany}`;
            const resp = await axios.get(url);
            setReviewsDB(resp.data);
            // console.log(resp.data);
            //order by date
            const reviews = resp.data.sort((a, b) => {
                return new Date(b.Date) - new Date(a.Date);
            });
            setReviewsDB(reviews);
        };
        getReviews();
    }, [idCompany]);
    const totalReviews = reviewsDB.map(item => (item.ReviewsStars >= 3 ? item.ReviewsStars : 0)).reduce((prev, curr) => prev + curr, 0);
    const porcReviews = (totalReviews / reviewsDB.length).toFixed(2)

    const ReadMore = ({ children }) => {
        const text = children;
        const [isReadMore, setIsReadMore] = useState(true);
        const toggleReadMore = () => {
            setIsReadMore(!isReadMore);
        };
        return (
            <p className="text-[15px] leading-relaxed">
                {/* {
                    rpdata?.works?.map((item, index) => {
                        return (
                            <div key={index} className="flex items-center justify-start">
                                <p className="text-[15px] font-semibold">{item.title}</p>
                                <p className="text-[15px] text-gray-400"> {item.description}</p>
                            </div>
                        );
                    })
                } */}
                {isReadMore ? text.substring(0, 60) : text}
                <span onClick={toggleReadMore} className="read-or-hide text-gray-400">
                    {isReadMore ? "...read more" : " show less"}
                </span>
            </p>
        );
    };

    const moverReview = [

        {
            Rate: 5.0,
            UserName: "Marc G.",
            Date: "7/23/2020",
            Description: "Install a Retaining Wall!"
        },
        {
            Rate: 5.0,
            UserName: "Shotaro S.",
            Date: "4/2/2019",
            Description: "Owner was very friendly. I also liked the use of Xypex product for waterproofing."
        },
        {
            Rate: 5.0,
            UserName: "Rowden D.",
            Date: "12/14/2018",
            Description: "great"
        },
        {
            Rate: 5.0,
            UserName: "Rowden D.",
            Date: "12/4/2018",
            Description: "Showed up on time, answered questions, got permit and removed debris. Sum pump install."
        },
        {
            Rate: 4.5,
            UserName: "H. l. R.",
            Date: "11/12/2018",
            Description: "On time, professional, accommodating, personable & easy to work with; also excellent work ethic. They explained what they were doing & why. Since it has only been 72 hours since they finished & no \"weather\" to test the project, I had no data & couldn't give a full 5 stars for quality & value, but I have reason for high hopes that time will prove them 5-star worth on all levels."
        },
        {
            Rate: 5.0,
            UserName: "Laurel M.",
            Date: "10/15/2018",
            Description: "They were efficient and did an excellent job!"
        },
        {
            Rate: 4.5,
            UserName: "William N.",
            Date: "10/7/2018",
            Description: "I made the appointment for work about three weeks in advance. They came on the day and on time as promised. We discussed scope of work and they started immediately. I was present most of the time they were working and they were wiling to discuss certain aspects of the job and made some minor alterations at no additional cost, Finished the job by 6:00 pm that day. in a workman -like manner. I would hire them again for a similar project if needed. I highly recommend them."
        },
        {
            Rate: 5.0,
            UserName: "Dale H.",
            Date: "9/17/2018",
            Description: "The team did an outstanding job. The were efficient and careful. Highly recommended ."
        },
        {
            Rate: 5.0,
            UserName: "Arthur M.",
            Date: "6/12/2018",
            Description: "I had water intrusion in my basement along one section where the wall meets the slab floor. Unfortunately, part of this leak was under a very tight space directly under an HVAC duct. Other companies told me the duct work would have to be dismantled for the job to be done. The AM team gave me a great quote, squeezed under the ductwork, and did a premium job! Excellent work, no up-charges and no hassles. I would hire them again without hesitation."
        },
        {
            Rate: 5.0,
            UserName: "Lori L.",
            Date: "6/5/2018",
            Description: "They were efficient, neat, pleasant, fair."
        },
        {
            Rate: 5.0,
            UserName: "Michele V.",
            Date: "5/29/2018",
            Description: "Very professional and friendly. Would hire them and recommend this company. Shows up when they say they will be there. Estimate was very fair. Love Love Love them! Thanks Loads...Michele and Dina"
        },
        {
            Rate: 5.0,
            UserName: "Carnell P.",
            Date: "5/12/2018",
            Description: "Excellent job"
        },
        {
            Rate: 5.0,
            UserName: "Gamal H.",
            Date: "4/23/2018",
            Description: "I would like to give a 6 star for customer service, they called very quickly and did excellent work quickly."
        },
        {
            Rate: 5.0,
            UserName: "Forrest W.",
            Date: "4/16/2018",
            Description: "The job is scheduled for completion this week. So far very good."
        },
        {
            Rate: 5.0,
            UserName: "Glenn M.",
            Date: "2/17/2018",
            Description: "I was really into the details asking lots of questions. They are very responsive and answer all of the questions. The work crew was very meticulous ensuring a clean worksite at the end of every"
        },
        {
            Rate: 5.0,
            UserName: "Moses C.",
            Date: "12/10/2017",
            Description: "KIko and his crew is extremely professional, experts, and trustworthy. Kiko and his crew go over and beyond to ensure customer satisfaction. I highly recommend this business to anyone looking for honest contractor. This review is only allowing me to select inspection waterproofing. A.M Waterproofers did complete waterproofing job on my home inside and out. I am very happy with the outcome ."
        },
        {
            Rate: 5.0,
            UserName: "Jim H.",
            Date: "11/25/2017",
            Description: "Very professional. Highly recommend."
        },
        {
            Rate: 4.5,
            UserName: "Sharon R.",
            Date: "11/18/2017",
            Description: "Waterproof or Encapsulate a Basement, Foundation or Crawlspace"
        },
        {
            Rate: 5.0,
            UserName: "Linda W.",
            Date: "11/18/2017",
            Description: "They are very personable always make sure that you're taking care of and understand what is being done and They go above and beyond"
        },
        {
            Rate: 5.0,
            UserName: "Anin D.",
            Date: "9/7/2017",
            Description: "Kiko and his crew were excellent at their job and finished everything in record time. We're very happy with their service"
        },
        {
            Rate: 5.0,
            UserName: "Lori L.",
            Date: "8/7/2017",
            Description: "AM came out, developed a repair plan, and executed it in a timely, friendly and professional manner at a very fair price. I couldn't be happier and recommend their company without reservation."
        },
        {
            Rate: 5.0,
            UserName: "Peter T.",
            Date: "5/20/2017",
            Description: "Oh sure he's Mr Personality during the free estimate, but can he get the job done right? Yes he can! An efficient and courteous crew identified my issue and corrected it in a day; and for thousands less than I feared. Highly recommend."
        },
        {
            Rate: 5.0,
            UserName: "Brenda H.",
            Date: "4/3/2017",
            Description: "Very pleased with service and quality of work. They were very professional, timely, and job included warranty. I would highly recommend. Thank you AM Professional; Job Well Done!!!"
        },
        {
            Rate: 5.0,
            UserName: "Scott F.",
            Date: "2/28/2017",
            Description: "Great job...very professional!"
        },
        {
            Rate: 5.0,
            UserName: "Jeff V.",
            Date: "9/23/2016",
            Description: "Well done AM! This was a complicated and unique repair job. Well done!"
        },
        {
            Rate: 5.0,
            UserName: "David E.",
            Date: "5/4/2016",
            Description: "Really professional group"
        }


    ]


    return (
        <section className="w-full flex flex-col items-center justify-center py-20 h-auto ">
            <div className="w-full flex flex-col bg-[#f3f3f3] h-auto rounded-xl">
                <div className="px-5 justify-start flex py-5 text-center flex-wrap">
                    <div className="flex items-center border-gray-500 border-b-[4px]">
                        <p className="px-2">All Reviews</p>
                        <p className="font-semibold"> 5.0 </p>
                    </div>

                    <div className="flex px-5 items-center">
                        <img src="https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/homeadvisor.png?alt=media&token=a0c6979c-44bd-4023-92e1-6b9db400dd49" alt="google svg" className="w-[25px] h-[25px]" />
                        <p className="px-2">HomeAdvisor</p>
                        <p className="font-semibold"> 5.0 </p>
                    </div>
                    {/* 
                    <div className="flex items-center">
                        <img src="https://acortar.link/cgmMNf" alt="google svg" className="w-[20px] h-[20px]" />
                        <p className="px-2">Yelp</p>
                        <p className="font-semibold"> 5.0 </p>
                    </div> */}
                </div>

                <div className="flex w-full h-auto px-5 border-t justify-between">
                    <div className="flex flex-col">
                        <div><p className="text-[20px]">Overall Rating</p></div>
                        <div className="flex">
                            <div><p className="font-bold text-[18px]">5.0</p></div>
                            <div className={`flex ${porcReviews >= 1 ? 'flex-row' : 'flex-col'} p-2 justify-start items-center gap-2`}>
                                <div className="flex">
                                    <FaStar className="text-[#ff4c4c] text-[20px]" />
                                    <FaStar className={`text-[#ff4c4c] text-[20px] ${porcReviews >= 1 ? '-ml-3' : ''}`} />
                                    <FaStar className={`text-[#ff4c4c] text-[20px] ${porcReviews >= 1 ? '-ml-3' : ''}`} />
                                    <FaStar className={`text-[#ff4c4c] text-[20px] ${porcReviews >= 1 ? '-ml-3' : ''}`} />
                                    <FaStar className={`text-[#ff4c4c] text-[20px] ${porcReviews >= 1 ? '-ml-3' : ''}`} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="py-7">
                        {/* <a href={rpdata?.gmb?.link} className="p-3 bg1 rounded-md text-white font-bold" target="_blank" rel='noopener noreferrer'>
                            Write a Review
                        </a> */}
                    </div>
                </div>
            </div>

            <div className="w-full h-auto my-10 mx-auto gap-5 grid md:grid-cols-5 grid-cols-1">
                {
                    moverReview.map((item, index) => {
                        return (
                            <article key={index} className="h-auto">

                                <div className="p-5 flex flex-col rounded-[28px] items-start mb-2 bg-[#f3f3f3] w-auto relative">
                                    <div className={`flex ${porcReviews >= 1 ? 'flex-row' : 'flex-col'} p-2 justify-start items-center gap-2 `}>
                                        <div className="flex">
                                            <FaStar className="text-[#ff4c4c] text-[20px]" />
                                            <FaStar className={`text-[#ff4c4c] text-[20px] ${porcReviews >= 1 ? '-ml-3' : ''}`} />
                                            <FaStar className={`text-[#ff4c4c] text-[20px] ${porcReviews >= 1 ? '-ml-3' : ''}`} />
                                            <FaStar ar className={`text-[#ff4c4c] text-[20px] ${porcReviews >= 1 ? '-ml-3' : ''}`} />
                                            <FaStar className={`text-[#ff4c4c] text-[20px] ${porcReviews >= 1 ? '-ml-3' : ''}`} />
                                        </div>
                                    </div>
                                    <ReadMore>{item?.Description}</ReadMore>

                                </div>
                                <div className="bg-[#f3f3f3] w-[28px] h-[18px] relative overflow-hidden rounded-br-[30px] -mt-2 ml-7">
                                    <div className="w-[45px] h-[100px] bg-white rounded-full absolute -left-8 -bottom-1">
                                    </div>
                                </div>

                                <div>
                                    <div className="flex flex-col">
                                        <div className="py-4 flex space-x-2">
                                            <div className="flex justify-center">
                                                <img src="https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/user.jfif?alt=media&token=2e7c59b5-a71c-4739-8260-bb88b37b08fa"
                                                    alt="perfil-foto"
                                                    className="w-[40px] h-[40px] rounded-full object-cover" />
                                            </div>

                                            <div>
                                                <div>
                                                    <span className="text-[15px] font-semibold"> {item?.UserName} </span>
                                                </div>
                                                <div className="flex items-end text-[11px] text-gray-400">
                                                    <span>{item?.Date}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </article>
                        );
                    })
                }
            </div>
        </section>
    );
}
